import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import AppMeeting from "./AppMeeting";
import AppConference from "./AppConference";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

const toastConfig = <ToastContainer
      toastClassName={() =>
        "relative flex py-4 px-3 rounded overflow-hidden cursor-pointer bg-white shadow-lg"
      }
      bodyClassName={() => "text-black text-base font-normal"}  position="bottom-left"  autoClose={4000}  hideProgressBar={true}  newestOnTop={false}
      closeButton={false}  closeOnClick   rtl={false}    pauseOnFocusLoss   draggable   pauseOnHover  theme="light"
    />


const router = createBrowserRouter([
  {
    path: "/",
    element: <> {toastConfig}  <AppMeeting />
    </>
  },
  {
    path: "meeting/",
    element: <> {toastConfig}  <AppMeeting />
    </>
  },
  {
    path: "conference/",
    element: <> {toastConfig}  <AppConference />
    </>
  },
]);


ReactDOM.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
  document.getElementById("root")
);
